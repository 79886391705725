<script setup>
import menus from "~/configs/menu";
import SocialMedia from "~/components/AppFooter/SocialMedia.vue";

const { blendEffect } = useBlend();

const visible = defineModel('visible');
const open = ref(visible.value);

blendEffect(visible, true, true, false, () => {
  if(visible.value) visible.value = false;
});

const hideMenu = () => {
  if(!open.value) return;
  visible.value = false;
}

</script>

<template>
  <div class="🟠 mobile-menu | fixed z-50 bg-white w-64 top-10 h-screen pt-40 md:hidden -right-64 transition-all duration-300"
       :class="{'!right-0': visible}"
       @transitionend="open = visible"
       @touchmove.passive.prevent=""
       v-click-outside="() => hideMenu()"
  >
    <div class="px-4">
      <div class="flex flex-col gap-12 w-full px-4">
        <NuxtLink v-for="menu in menus"
                  :href="menu.path"
                  class="font-extralight text-lg hover:text-azure cursor-pointer transition-colors"
                  @click="visible = false"
        >
          {{ menu.label }}
        </NuxtLink>
        <NuxtLink href="https://vehis.pl/moto" target="_blank">
          <VectorMoto />
        </NuxtLink>
      </div>
      <div class="px-4 pt-6">
        <SocialMedia noVehisLogos forceColumn />
      </div>
    </div>
  </div>
</template>
